import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "inviteBox",
        "inviteBoxDropdowns",
        "inviteBoxButton",
        "inviteBoxDisabledButton",
        "inviteBoxEmailLabel",
        "inviteBoxRoleLink",
        "inviteBoxRoleLabel",
        "inviteBoxRoleOption",
        "inviteBoxLocaleAccessLink",
        "inviteBoxLocaleAccessLabel",
        "inviteBoxTeamAccessLink",
        "inviteBoxTeamAccessLabel",
        "inviteBoxProjectAccessLink",
        "inviteBoxProjectAccessLabel",
        "searchInput",
        "locale",
        "permission",
        "team",
        "project"
    ];

    element: HTMLDivElement;
    invitationRole: string;
    inviteBoxTarget: HTMLDivElement;
    inviteBoxDropdownsTarget: HTMLDivElement;
    inviteBoxEmailLabelTarget: HTMLSpanElement;
    inviteBoxRoleLinkTarget: HTMLLinkElement;
    inviteBoxRoleLabelTarget: HTMLSpanElement;
    inviteBoxLocaleAccessLinkTarget: HTMLLinkElement;
    inviteBoxLocaleAccessLabelTarget: HTMLSpanElement;
    inviteBoxButtonTarget: HTMLLinkElement;
    inviteBoxDisabledButtonTarget: HTMLLinkElement;
    inviteBoxTeamAccessLinkTarget: HTMLLinkElement;
    inviteBoxTeamAccessLabelTarget: HTMLSpanElement;
    inviteBoxProjectAccessLinkTarget: HTMLLinkElement;
    inviteBoxProjectAccessLabelTarget: HTMLSpanElement;

    searchInputTarget: HTMLFormElement;
    localeTargets: HTMLInputElement[];
    permissionTargets: HTMLInputElement[];
    teamTargets: HTMLInputElement[];
    projectTargets: HTMLInputElement[];

    private hasInviteBoxButtonTarget: boolean;
    private hasInviteBoxTeamAccessLinkTarget: boolean;
    private hasInviteBoxProjectAccessLinkTarget: boolean;

    connect() {
        this.invitationRole = '';
    }

    updateInvitationForm() {
        // Update Invite Box with input from search field
        this.inviteBoxEmailLabelTarget.innerText = this.invitationEmail;

        // Toggle Invite Box
        if (this.invitationEmail.length > 2) {
            this.inviteBoxTarget.classList.remove("hidden");

            this.updateDropdowns();
            this.updateInviteButton();
        } else {
            this.resetInviteBox();
        }
    }

    selectRole(event: Event) {
        event.preventDefault();
        const target = event.target as HTMLElement;
        const selectedElement = target.closest('[data-display-role]') as HTMLElement;
        this.invitationRole = selectedElement.dataset.role;
        this.inviteBoxRoleLabelTarget.textContent = selectedElement.getAttribute('data-display-role');
        this.markParentDropdownAsSelected(selectedElement);
        this.updateDropdowns();
        this.updateLocaleAccessLabel();
        this.updateProjectAccessLabel();
        this.updateInviteButton();
    }

    toggleLocale(event: Event) {
        this.updateLocaleAccessLabel();
        this.updateInviteButton();
    }

    togglePermission(event: Event) {
        this.updateInviteButton();
    }

    toggleTeam(event: Event) {
        this.updateTeamAccessLabel();
    }

    toggleProject(event: Event) {
        this.updateProjectAccessLabel();
    }

    resetInviteBox() {
        if (!this.hasInviteBoxButtonTarget) return;

        this.disableInviteBoxButton();

        // Hide Invite Box
        this.inviteBoxTarget.classList.add("hidden");

        this.resetDropdowns();
    }

    updateDropdowns() {
        if (this.isEmailValid()) {
            this.inviteBoxDropdownsTarget.classList.remove('hidden');
            this.inviteBoxRoleLinkTarget.classList.remove('disabled');
            this.inviteBoxRoleLinkTarget.closest('[data-target="syntax-dropdown.anchor"]')?.classList.remove("disabled");
            if (this.invitationRole !== '') {
                this.inviteBoxLocaleAccessLinkTarget.classList.remove('disabled');
                this.inviteBoxLocaleAccessLinkTarget.closest('[data-target="syntax-dropdown.anchor"]')?.classList.remove("disabled");
                if (this.hasInviteBoxTeamAccessLinkTarget) {
                    this.inviteBoxTeamAccessLinkTarget.classList.remove('disabled');
                    this.inviteBoxTeamAccessLinkTarget.closest('[data-target="syntax-dropdown.anchor"]')?.classList.remove("disabled");
                }
                if (this.hasInviteBoxProjectAccessLinkTarget) {
                    this.inviteBoxProjectAccessLinkTarget.classList.remove('disabled');
                    this.inviteBoxProjectAccessLinkTarget.closest('[data-target="syntax-dropdown.anchor"]')?.classList.remove("disabled");
                }
            }
        } else {
            this.resetDropdowns();
        }
    }

    private disableInviteBoxButton() {
        this.inviteBoxButtonTarget.classList.add('hidden');
        this.inviteBoxDisabledButtonTarget.classList.remove('hidden');
    }

    private enableInviteBoxButton() {
        this.inviteBoxButtonTarget.classList.remove('hidden');
        this.inviteBoxDisabledButtonTarget.classList.add('hidden');
    }

    private resetDropdowns() {
        this.inviteBoxDropdownsTarget.classList.add('hidden');
        this.inviteBoxRoleLinkTarget.classList.add('disabled');
        this.inviteBoxRoleLinkTarget.closest('[data-target="syntax-dropdown.anchor"]')?.classList.add("disabled");
        this.inviteBoxLocaleAccessLinkTarget.classList.add('disabled');
        this.inviteBoxLocaleAccessLinkTarget.closest('[data-target="syntax-dropdown.anchor"]')?.classList.add("disabled");
        if (this.hasInviteBoxTeamAccessLinkTarget) {
            this.inviteBoxTeamAccessLinkTarget.classList.add('disabled');
            this.inviteBoxTeamAccessLinkTarget.closest('[data-target="syntax-dropdown.anchor"]')?.classList.add("disabled");
        }
        if (this.hasInviteBoxProjectAccessLinkTarget) {
            this.inviteBoxProjectAccessLinkTarget.classList.add('disabled');
            this.inviteBoxProjectAccessLinkTarget.closest('[data-target="syntax-dropdown.anchor"]')?.classList.add("disabled");
        }

        // Reset Role Label in Invite Box
        this.inviteBoxRoleLabelTarget.innerText = this.inviteBoxRoleLabelTarget.dataset.defaultText;
        this.inviteBoxLocaleAccessLabelTarget.innerText = this.inviteBoxLocaleAccessLabelTarget.dataset.defaultText;

        // Reset All Dropdowns in Invite Box
        this.inviteBoxTarget
            .querySelectorAll('.invite-box__meta .dropdown.selected')
            .forEach(dropdown => dropdown.classList.remove("selected"));
    }

    private updateInviteButton() {
        if (!this.hasInviteBoxButtonTarget) return;
        this.inviteBoxButtonTarget.href = this.getInvitationsUrl();

        if (this.isFormValid()) {
            this.enableInviteBoxButton();
        } else {
            this.disableInviteBoxButton();
        }
    }

    private getInvitationsUrl(): string  {
        const [path, paramsString] = this.inviteBoxButtonTarget.dataset.url.split('?');
        const searchParams = new URLSearchParams(paramsString);

        const params = {
            email: this.invitationEmail,
            role: this.invitationRole
        };

        for (const [key, value] of Object.entries(params)) {
            searchParams.append('invitation[' + key + ']', value);
        }

        this.selectedPermissions.forEach((permission) => {
            searchParams.append('invitation[permissions]['+ permission.value +']', '1');
        });

        this.selectedLocales.forEach((locale) => {
            searchParams.append('invitation[default_locale_codes][]', locale.value);
        });

        return [path, searchParams].join('?');
    }

    private updateLocaleAccessLabel() {
        const rolesWithoutLocaleAssignment = JSON.parse(this.inviteBoxTarget.dataset.rolesWithoutLocaleAssignment);
        const selectedLocales = this.selectedLocales;

        this.updateLocalesDropdownSelectedStatus(selectedLocales.length);

        const codes = selectedLocales.slice(0, 3).map((locale) => locale.value);

        if (rolesWithoutLocaleAssignment.includes(this.invitationRole) || selectedLocales.length == this.localeTargets.length) {
            this.element.dataset.invitationValid = "true";
            this.inviteBoxLocaleAccessLabelTarget.innerText = this.inviteBoxLocaleAccessLabelTarget.dataset.allText;
            
            if (rolesWithoutLocaleAssignment.includes(this.invitationRole)) {
                this.inviteBoxLocaleAccessLinkTarget.classList.add("disabled");
                this.inviteBoxLocaleAccessLinkTarget.closest('[data-target="syntax-dropdown.anchor"]')?.classList.add("disabled");
                this.unselectAllLocales();
            }
        }
        else if (codes.length > 0) {
            this.element.dataset.invitationValid = "true";
            this.inviteBoxLocaleAccessLabelTarget.innerText = `${this.inviteBoxLocaleAccessLabelTarget.dataset.accessText} `;
            this.inviteBoxLocaleAccessLabelTarget.innerText += codes.join(', ');
            if (selectedLocales.length > codes.length) {
                this.inviteBoxLocaleAccessLabelTarget.innerText += `, +${selectedLocales.length - codes.length}`;
            }
        } else {
            this.element.dataset.invitationValid = "false";
            this.inviteBoxLocaleAccessLabelTarget.innerText = this.inviteBoxLocaleAccessLabelTarget.dataset.defaultText;
        }
    }

    private updateTeamAccessLabel() {
        const count = this.selectedTeams.length;

        if (count > 0) {
            this.displayLabelForFilledInTeams(count);
        } else {
            this.displayLabelForNotFilledInTeams();
        }
    }

    private updateProjectAccessLabel() {
        if(!this.hasInviteBoxProjectAccessLinkTarget) return;

        const rolesWithFullProjectAccess = JSON.parse(this.inviteBoxTarget.dataset.rolesWithFullProjectAccess);
        const count = this.selectedProjects.length;
        this.inviteBoxProjectAccessLinkTarget.classList.remove("disabled");
        this.inviteBoxProjectAccessLinkTarget.closest('[data-target="syntax-dropdown.anchor"]')?.classList.remove("disabled");

        if (rolesWithFullProjectAccess.includes(this.invitationRole)) {
            this.displayLabelForDisabledProjects();
        } else if (count > 0) {
            this.displayLabelForFilledInProjects(count);
        } else {
            this.displayLabelForNotFilledInProjects();
        }
    }

    private displayLabelForDisabledProjects() {
        this.inviteBoxProjectAccessLinkTarget.classList.add("disabled");
        this.inviteBoxProjectAccessLabelTarget.innerText = this.inviteBoxProjectAccessLabelTarget.dataset.allText;
        this.inviteBoxProjectAccessLabelTarget.closest('[data-target="syntax-dropdown.anchor"]')?.classList.add("disabled");
    }

    private displayLabelForNotFilledInProjects() {
        this.inviteBoxProjectAccessLabelTarget.innerText = this.inviteBoxProjectAccessLabelTarget.dataset.defaultText;
        this.unmarkDropdownAsSelected(this.inviteBoxProjectAccessLabelTarget);
    }

    private displayLabelForFilledInProjects(count: number) {
        this.inviteBoxProjectAccessLabelTarget.innerText = this.inviteBoxProjectAccessLabelTarget.dataset.accessText.replace("%{count}", count.toString());
        this.markParentDropdownAsSelected(this.inviteBoxProjectAccessLabelTarget);
    }

    private displayLabelForNotFilledInTeams() {
        this.inviteBoxTeamAccessLabelTarget.innerText = this.inviteBoxTeamAccessLabelTarget.dataset.defaultText;
        this.unmarkDropdownAsSelected(this.inviteBoxTeamAccessLabelTarget);
    }

    private displayLabelForFilledInTeams(count: number) {
        this.inviteBoxTeamAccessLabelTarget.innerText = this.inviteBoxTeamAccessLabelTarget.dataset.accessText.replace("%{count}", count.toString());
        this.markParentDropdownAsSelected(this.inviteBoxTeamAccessLabelTarget);
    }

    private get selectedLocales(): HTMLInputElement[] {
        const selected = (locale) => { return locale.checked; };
        return this.localeTargets.filter(selected);
    }

    private get selectedPermissions(): HTMLInputElement[] {
        const selected = (permission) => { return permission.checked; };
        return this.permissionTargets.filter(selected);
    }

    private get selectedTeams(): HTMLInputElement[] {
        const selected = (team) => { return team.checked; };
        return this.teamTargets.filter(selected);
    }

    private get selectedProjects(): HTMLInputElement[] {
        const selected = (project) => { return project.checked; };
        return this.projectTargets.filter(selected);
    }

    private isFormValid(): boolean {
        return(this.isEmailValid() && this.isRoleSelected());
    }

    private isEmailValid(): boolean {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.invitationEmail);
    }

    private isRoleSelected(): boolean {
        return(!!this.invitationRole);
    }

    private unselectAllLocales() {
        this.localeTargets.forEach((localeTarget) => localeTarget.checked = false);
    }

    private get invitationEmail(): string {
        return this.searchInputTarget.value;
    }

    private updateLocalesDropdownSelectedStatus(selectedLocalesCount: number) {
        if (selectedLocalesCount > 0) {
            this.markParentDropdownAsSelected(this.inviteBoxLocaleAccessLinkTarget);
        } else {
            this.unmarkDropdownAsSelected(this.inviteBoxLocaleAccessLinkTarget);
        }
    }

    private markParentDropdownAsSelected(element: HTMLElement) {
        // Still needed for old dropdowns
        element.closest('.dropdown')?.classList.add('selected');

        // Syntax dropdowns
        element.closest('[data-controller="syntax-dropdown"]')?.querySelector('.dropdown').classList.add('selected');
    }

    private unmarkDropdownAsSelected(element: HTMLElement) {
        // Still needed for old dropdowns
        element.closest('.dropdown')?.classList.remove('selected');

        // Syntax dropdowns
        element.closest('[data-controller="syntax-dropdown"]')?.querySelector('.dropdown').classList.remove('selected');
    }
}
