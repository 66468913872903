import Sentry from "@utils/sentry";
import DataUtils from "@utils/data_utils";
import { Controller } from "stimulus";
import Spinner from "../util/spinner";
import Tracker from "@utils/tracker";

export default class extends Controller {
    static targets = [
        "modal",
        "modalBody",
        "locale",
        "branch",
        "form",
        "exportButton",
        "selectAllCheckbox"
    ];

    private modalTarget: HTMLElement;
    private localeTargets: HTMLInputElement[];
    private branchTarget: HTMLInputElement;
    private hasBranchTarget: boolean;
    private modalBodyTarget: HTMLElement;
    private exportButtonTarget: HTMLButtonElement;
    private selectAllCheckboxTarget: HTMLInputElement;
    private formTarget: HTMLFormElement;
    private hasExportButtonTarget: boolean;
    private hasSelectAllCheckboxTarget: boolean;
    private selectedLocales: string[];

    connect() {
        if (this.hasExportButtonTarget) {
            this.exportButtonTarget.disabled = true;
        }
    }

    async show(event: Event) {
        event.preventDefault();
        (<any>window).$(this.modalTarget).modal("show");

        const spinner = new Spinner(this.modalBodyTarget);
        spinner.start();

        await this.fetchBody();
    }

    download(event: Event) {
        event.preventDefault();
        Tracker.track("Clicked download button [export modal]");
        this.formTarget.submit();
    }

    async downloadLocale(event: Event) {
        const target = event.currentTarget as HTMLElement;
        const locale = target.dataset.downloadModalLocaleName;
        const title = target.dataset.downloadModalTitle;
        this.modalTarget.querySelector(".modal-title").textContent = title;
        this.selectedLocales = [locale];
        await this.show(event);
    }

    selectAll() {
        if (!this.hasSelectAllCheckboxTarget) { return; }
        const allLocales = this.localeTargets.map(locale => locale.value);
        if (!this.selectAllCheckboxTarget.checked) {
            this.selectedLocales = [];
            if (this.hasExportButtonTarget) {
                this.exportButtonTarget.disabled = true;
                this.exportButtonTarget.classList.add("syn_btn--disabled");
            }
        } else {
            if (this.hasExportButtonTarget) {
                this.exportButtonTarget.disabled = false;
                this.exportButtonTarget.classList.remove("syn_btn--disabled");
            }
            this.selectedLocales = allLocales;
        }
    }

    selectLocale() {
        const locales = this.selectedLocaleNames;
        if (this.hasExportButtonTarget) {
            this.exportButtonTarget.disabled = locales.length === 0;
        }
        this.selectedLocales = locales;

        Tracker.track("Clicked download button on batch action [Languages]");
    }

    private get selectedLocaleNames(): string[] {
        return this.localeTargets
            .filter(checkbox => checkbox.checked)
            .map(locale => locale.value);
    }

    private async fetchBody() {
        let branch = "";
        if (this.hasBranchTarget) {
            branch = this.branchTarget.value;
        }

        await DataUtils.request(
            this.data.get("downloadUrl"),
            { "locale_names[]": this.selectedLocales, branch: branch },
            {
                parse: false
            },
            {
                method: "GET",
                credentials: "same-origin",
                headers: { "X-Requested-With": "fetch" }
            })
            .then(async (data) => {
                this.modalBodyTarget.innerHTML = await data.text();
            })
            .catch(err => {
                Sentry.notify(err);
            });
    }
}
